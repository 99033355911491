import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AussichtLeichteSprache from "../../../content/shared/aussicht-leichte-sprache"
import Breadcrumb from "../../../components/breadcrumb"
import Comment from "../../../components/comment"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import Heading from "../../../components/heading"
import VideoPlayer from "../../../components/video-player"

const HoelderlinLiebtAussicht = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-aussicht.jpg" }
        ) {
          ...mediumImage
        }
        tisch: file(relativePath: { eq: "leichte-sprache/tisch.jpg" }) {
          ...mediumImage
        }
        lotte: file(relativePath: { eq: "hoelderlin-liebt/lotte-zimmer.jpg" }) {
          ...avatarImage
        }
        hoelderlin: file(
          relativePath: { eq: "hoelderlin-liebt/portrait-hoelderlin.jpg" }
        ) {
          ...avatarImage
        }
        posterAussicht: file(
          relativePath: { eq: "hoelderlin-liebt/poster-aussicht.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Aussicht"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… die Aussicht",
                link: "/sonderausstellungen/hoelderlin-liebt/aussicht",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt die Aussicht</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Paragraph dropcap={true}>
              Hölderlins Zimmer im Turm ist klein. <br />
              Er hat dort sein Bett und einen Tisch. <br />
              Später kommt noch ein Sofa hinzu.
              <br />
              Aber das Zimmer hat auch etwas ganz Besonderes:
              <br />
              Fünf Fenster!
              <br />
              Hölderlin kann von seinem Tisch aus weit ins Land sehen.
              <br />
              Heute hat das Zimmer nur noch drei Fenster.
              <br />
              Der Turm ist nach Hölderlins Tod abgebrannt.
              <br />
              Das Gebäude hat sich verändert.
            </Paragraph>

            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt Hölderlin am Schreibtisch."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Paragraph>
            Auch im Turm schreibt Hölderlin noch Gedichte. <br />
            Er schreibt über die Jahreszeiten. <br />
            Und über die Aussicht aus seinem Fenster. <br />
            Dabei klopft er mit der Hand auf den Schreibtisch. <br />
            Damit er den passenden Rhythmus <br />
            für seine Gedichte findet.
          </Paragraph>
          <Constrain align="right">
            <Image
              image={data.tisch.childImageSharp.gatsbyImageData}
              alt="Ein heller Raum mit einem alten Holzboden und einem kleinen Schreibtisch in der Mitte."
              attribution="David Franck"
              caption="So sieht Hölderlins Zimmer heute aus."
            />
          </Constrain>
        </Stack>

        <Stack>
          <Paragraph>
            Lotte erinnert sich: <br />
          </Paragraph>
          <Comment
            image={data.lotte.childImageSharp.gatsbyImageData}
            title="Lotte"
            alt="Eine Illustration zeigt Lotte Zimmer. Sie hat lange Haare und neigt den Kopf zur Seite."
          >
            <Paragraph size={[3, 3, 4]}>
              Hölderlin hat immer gerne am Fenster gesessen. <br />
              Als Kind habe ich ihn oft gefragt: <br />
              »Aber Hölderlin, ist das nicht langweilig? <br />
              Da ist doch immer dasselbe: <br />
              der Fluss, die Bäume, die Straßen, die Felder, der Himmel. <br />
              Jeden Tag. Da ändert sich doch nichts.« <br />
              Da hat der Hölderlin nur gelacht.
            </Paragraph>
          </Comment>
          <Paragraph>Heute weiß Lotte:</Paragraph>
          <Comment
            image={data.lotte.childImageSharp.gatsbyImageData}
            title="Lotte"
            alt="Eine Illustration zeigt Lotte Zimmer. Sie hat lange Haare und neigt den Kopf zur Seite."
          >
            <Paragraph size={[3, 3, 4]}>
              Das hat dem Hölderlin gefallen. <br />
              Der Fluss, die Bäume, die Straßen, die Felder, der Himmel: <br />
              Alles jeden Tag an derselben Stelle. <br />
              Für Hölderlin war der Blick aus dem Fenster ein Blick in die
              Ewigkeit.
            </Paragraph>
          </Comment>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Gedicht ›Aussicht‹
          </Heading>
          <Paragraph>
            Eines von Hölderlins Gedichten über die Aussicht <br />
            sehen sie hier <br />
            in Hölderlins Handschrift. <br />
            So eine Gedicht-Handschrift nennt man: <br />
            Manuskript.
          </Paragraph>
          <Constrain>
            <AussichtLeichteSprache />
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Gedicht ›Die Aussicht.‹ in Gebärdensprache
          </Heading>
          <VideoPlayer
            src="hoelderlin-liebt/aussicht"
            poster={data.posterAussicht}
          />
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtAussicht
